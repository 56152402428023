@import url("react-date-range/dist/styles.css");
@import url("react-date-range/dist/theme/default.css");

// Override date picker component styles
:root {
  --range-label-color: $text-primary;
}

// change color when using dark theme
[data-theme="dark"] {
  --range-label-color: #ffffff;
}

// hide range input fields
.rdrDateDisplayWrapper,
.rdrInputRanges {
  display: none;
}

.rdrMonthAndYearWrapper {
  height: unset;
  padding: 0 0 0 0.625rem;
}

// edit LHS predefined ranges options
.rdrDefinedRangesWrapper {
  background: transparent;
  margin: auto 0;
  order: 2;
  border-right: 0;

  button {
    border-left: 0.0625rem solid $bg-grey;
    background: transparent;
  }
}

// hide preDefined ranges option on small screens
@media (max-width: 62rem) {
  .rdrDefinedRangesWrapper {
    display: none;
  }
}

// remove background from elements
.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: transparent !important;
}

// add padding to elements
.rdrCalendarWrapper.rdrDateRangeWrapper {
  padding: 0 1.25rem;
}

.rdrStaticRangeLabel {
  padding-left: 1.875rem;
  color: var(--range-label-color);
}

// set height to a day button
.rdrDay {
  height: calc($rdrDay-selected + 0.625rem);
}

// set border radius for selected days
// and terminal days in selected range
.rdrStartEdge,
.rdrDayStartPreview,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrEndEdge,
.rdrDayStartOfWeek .rdrDayEndPreview,
.rdrDayStartOfMonth,
.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrInRange {
  border-top-left-radius: calc($rdrDay-selected / 2);
  border-bottom-left-radius: calc($rdrDay-selected / 2);
}

.rdrEndEdge,
.rdrDayEndPreview,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrStartEdge,
.rdrDayEndOfWeek .rdrDayStartPreview,
.rdrDayEndOfMonth,
.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfMonth .rdrInRange {
  border-top-right-radius: calc($rdrDay-selected / 2);
  border-bottom-right-radius: calc($rdrDay-selected / 2);
}

// set color to Edge dates of selected range
.rdrStartEdge,
.rdrEndEdge {
  ~ span > span {
    color: $white !important;
  }

  &::before {
    content: "";
  }
}

// shift pseudo background elements of edge dates
.rdrStartEdge::before {
  right: -0.188rem;
}

.rdrEndEdge::before {
  left: -0.189rem;
}

// color for selected preDefined range
.rdrStaticRange.rdrStaticRangeSelected {
  .rdrStaticRangeLabel {
    color: $pumpkin !important;
  }
}

// underline color for today's date
.rdrDayToday .rdrDayNumber span::after {
  background-color: $pumpkin;
}

// week day display
.rdrWeekDay {
  text-transform: uppercase;
  opacity: 0.4;
}

.rdrStaticRange {
  border: 0;
}

// edit next button
.rdrNextPrevButton {
  background: none;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    background: none;
  }
}

// previous and next button icon design
.rdrNextPrevButton i {
  border: $nextPrevButton-height solid transparent;
  position: relative;
  width: 0;

  &::after {
    content: "";
    position: absolute;
    top: -$nextPrevButton-height;
    border: $nextPrevButton-height solid transparent;
  }
}

// previous button icon design
.rdrPprevButton i {
  border-right: $nextPrevButton-height solid $text-primary;

  &::after {
    border-right: $nextPrevButton-height solid $white;
    left: -($nextPrevButton-height - 0.1875rem);
  }
}

// next button icon design
.rdrNextButton i {
  border-left: $nextPrevButton-height solid $text-primary;

  &::after {
    border-left: $nextPrevButton-height solid $white;
    right: -($nextPrevButton-height - 0.1875rem);
  }
}

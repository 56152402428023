.custom-scroll-bar {
  // styles for firefox browser
  scrollbar-color: $scrollbar-thumb-color transparent;
  scrollbar-width: auto;

  // styles only for Blink and WebKit based browsers
  // (e.g., Chrome, Edge, Opera, Safari, all browsers on iOS, and others)
  &::-webkit-scrollbar {
    width: 0.4rem;
    background-color: $scrollbar-background-color;
    border-radius: 0.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: 0.2rem;

    &:hover {
      background-color: $scrollbar-thumb-hover-color;
    }
  }
}

// TODO(YM): CONVERT TO SASS NESTING
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress { 
  background: $blueViolet;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $blueViolet, 0 0 5px $blueViolet;
  opacity: 1.0;
  transform: rotate(3deg) translate(0, -4px);
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: $blueViolet;
  border-left-color: $blueViolet;
  border-radius: 10px;
  animation: pace-spinner 400ms linear infinite;
}

@keyframes pace-spinner {
  0% { 
    transform: rotate(0deg);
  }

  100% { 
    transform: rotate(360deg); 
  }
}

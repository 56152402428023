@mixin for-extra-large-desktop-only {
  @media (min-width: $extraLargeDesktopMin) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: $desktopMin) {
    @content;
  }
}

@mixin for-tablet-and-mobile {
  @media (max-width: $desktopMin) {
    @content;
  }
}

@mixin for-tablet-only {
  @media (min-width: $tabletMin) and (max-width: $desktopMin) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: $tabletMin) {
    @content;
  }
}

// Project layout

* {
  outline: none;
}

body {
  font-family: $font-default-set;
}

.tableViewLayout {
  width: calc(100% - 15.75rem);
  min-height: 100vh;

  @include for-tablet-only {
    width: calc(100% - 12.75rem);
  }

  @include for-phone-only {
    width: 100%;
  }
}

.table-view-layout-content {
  padding: 0 1.5rem 2.5rem;

  @include for-phone-only {
    padding: 0 1.125rem 1.5rem;
  }
}

.home-container {
  max-width: 120rem;
  margin: 0 auto;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-path}/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: $font-thin;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-path}/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: $font-light;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-path}/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: $font-regular;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-path}/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: $font-medium;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-path}/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: $font-semi-bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-path}/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: $font-bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$font-path}/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: $font-black;
  font-style: normal;
}

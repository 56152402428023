// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$font-default-set: "Poppins", "sans-serif";

/// Copy text color
/// @type Color
$black: #000000;
$white: #ffffff;
$bg-grey: #f4f5f7;
$accentred: #fe6258;
$blueViolet: #2601cb;
$text-primary: #253858;
$icon-grey: #5c6a82;
$charcoal: #3d4154;
$clear-water: #5eacd8;
$pumpkin: #fb803a;
$light-orange: #fb803a20;
$text-grey: #6b6a76;
$almost-black: #0e121e;
$deep-blue: #3f4c76;

/// colors and gradient for signin, register, forgot password and reset password screen
$auth-bg: #ffa46e;

$auth-bg-gradient-color1: #ff8785;
$auth-bg-gradient-color2: #ffa270;
$auth-bg-gradient-color3: #ffa66b;
$auth-bg-gradient-color4: #ffb1ad;
$auth-bg-gradient-color5: #f68486;
$auth-bg-gradient-color6: #fcd8ab;
$auth-bg-gradient-color7: #fe7777;
$auth-bg-gradient-color8: #e5cbbd;
$auth-bg-gradient-color9: #76b4db;
$auth-bg-gradient-color10: #ffaf47;
$auth-bg-gradient-color11: #ffc870;
$auth-bg-gradient-color12: #ffb6ad;
$auth-bg-gradient-color13: #cde5f4;

$auth-bg-gradient: radial-gradient(
    at 51% 78%,
    $auth-bg-gradient-color1 0,
    transparent 26%
  ),
  radial-gradient(at 3% 65%, $auth-bg-gradient-color2 0, transparent 15%),
  radial-gradient(at 1% 98%, $auth-bg-gradient-color3 0, transparent 20%),
  radial-gradient(at 82% 76%, $auth-bg-gradient-color4 0, transparent 15%),
  radial-gradient(at 28% 58%, $auth-bg-gradient-color5 0, transparent 26%),
  radial-gradient(at 68% 51%, $auth-bg-gradient-color6 0, transparent 52%),
  radial-gradient(at 47% 71%, $auth-bg-gradient-color7 0, transparent 55%),
  radial-gradient(at 53% 40%, $auth-bg-gradient-color8 0, transparent 24%),
  radial-gradient(at 0% 34%, $auth-bg-gradient-color9 0, transparent 21%),
  radial-gradient(at 99% 1%, $auth-bg-gradient-color10 0, transparent 23%),
  radial-gradient(at 99% 32%, $auth-bg-gradient-color11 0, transparent 20%),
  radial-gradient(at 63% 1%, $auth-bg-gradient-color12 0, transparent 44%),
  radial-gradient(at 10% 0%, $auth-bg-gradient-color13 0, transparent 64%);

// Custom scrollbar colors
$scrollbar-background-color: #7f87a320;
$scrollbar-thumb-color: #7f87a360;
$scrollbar-thumb-hover-color: #7f87a390;

// Paths
$font-path: "../../assets/fonts/";

// Font weights
$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-black: 900;

// media query breakpoints
$mobileMin: 0;
$tabletMin: 48rem;
$desktopMin: 64rem;
$largeDesktopMin: 80rem;
$extraLargeDesktopMin: 120rem;

// datePicker dimensions
$rdrDay-selected: 2.25rem;
$nextPrevButton-height: 0.5rem;

// Commonly used CSS classes

// Flex
.d-flex {
  display: flex !important;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.align-flex-start {
  align-items: flex-start;
}

.align-center {
  align-items: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-start {
  justify-content: flex-start;
}

.flex-column-md {
  @include for-tablet-and-mobile {
    flex-direction: column !important;
  }
}

.flex-column-sm {
  @include for-phone-only {
    flex-direction: column !important;
  }
}

.flex-gap-6 {
  gap: 0.375rem;
}

.flex-gap-16 {
  gap: 1rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

// Padding
.padding-55 {
  // TODO: create px to rem function
  padding: 55px;
}

.padding-10 {
  padding: 10px;
}

.padding-5 {
  padding: 5px;
}

.padding-25 {
  padding: 25px;
}

.padding-0 {
  padding: 0 !important;
}

.padding-12 {
  padding: 12px !important;
}

// Margin
.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-right-10 {
  margin-right: 10px;
}

// Fonts
.font-cabinet-grostek {
  font-family: "CabinetGrotesk" !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-normal {
  font-weight: normal;
}

.wt-600 {
  font-weight: 600 !important;
}

// Errors
.error {
  font-size: 14px !important;
  color: $accentred;
}

// Widths
.w-100 {
  width: 100%;
}

// Cursors
.cursor-pointer {
  cursor: pointer;
}

// Colors
.bg-grey {
  background-color: $bg-grey;
}

.bg-gradient {
  background-color: $auth-bg;
  background-image: $auth-bg-gradient;
}

.bg-white {
  background-color: $white;
}

.primary-text {
  color: $text-primary;
}

%text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Truncate text overflow
.text-truncate {
  @extend %text-ellipse;
}

.text-truncate-with-width {
  @extend %text-ellipse;

  width: 10vw;
}

// Positions
.position-relative {
  position: relative;
}

.position-relative-sm {
  @include for-phone-only {
    position: relative;
  }
}

// heights
.min-height-400 {
  min-height: 400px;
}

.min-height-100vh {
  min-height: 100vh;
}

// Text align
.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-left-sm {
  @include for-phone-only {
    text-align: left !important;
  }
}

// Texts
.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-size-22 {
  font-size: 22px !important;
}

// Table
// Pop-over icons
.table-option-icon {
  font-size: 22px;
  margin-right: 10px;
  color: $icon-grey;
}

// Links
.grey-link {
  cursor: pointer;
  color: $text-grey;

  &:hover {
    text-decoration: underline;
  }
}

.link-primary {
  cursor: pointer;
  color: $clear-water;

  &:hover {
    color: deep-blue;
  }
}

// display
.display-inline-block {
  display: inline-block;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

// radius
.no-border-radius {
  border-radius: 0 !important;
}

.w-50vw {
  width: 50vw;
}

.shift-right-to-sidebar {
  margin-left: 15.75rem;

  @include for-tablet-only {
    margin-left: 12.75rem;
  }

  @include for-phone-only {
    margin-left: 0;
  }
}

// float
.float-right {
  float: right;
}
